//partials
@import './front/base/_appfront';

//vendors
@import './front/vendors/swiper';
 
// custom styles

.full-container{
    margin: auto;
    max-width: 1900px}

.top-page{
    position: relative;
    min-height: 600px;
    background: rgb(249,249,249);
    background: linear-gradient(90deg, rgba(249,249,249,1) 0%, rgba(255,255,255,1) 100%);
    
    &-intro{
        position: relative;
        z-index: 1000;
        margin-top: 100px;
        width: 50%;
        padding-right: 7%;
        h1{font-size: 50px}
        span{
            display: block;
            font-weight: 100;
            font-sizey: 35px;
        }
        .desc{font-size:18px}
        .prix{margin-top:5%;
            strong{font-size: 20px;color:$primary}
            span{color:$secondary;font-size: 40px;font-weight:900}
        }
        
        
    }
    &-img{
        position:relative;
        z-index: 100;
        padding: 3%;
        margin-top: 100px;
        text-align: right;
        width: 49%;
    }
    &-marque{
        position: absolute;
        z-index: 10;
        top: 2%;
        left: 5%;
        strong{
            font-family: 'oswald';
            font-size: 200px;
            color:#ebebeb;
            font-weight: 900;
        }
    }
}

.reveal-text,
.reveal-text::after {
	animation-delay: var(--animation-delay, 2s);
	animation-iteration-count: var(--iterations, 1);
	animation-duration: var(--duration, 800ms);
	animation-fill-mode: both;
	animation-timing-function: cubic-bezier(0.0, 0.0, 0.2, 1);
}

.reveal-text {
	--animation-delay: var(--delay, 0);
	--animation-duration: var(--duration, 800ms);
	--animation-iterations: var(--iterations, 1);
	position: relative;
	font-size: 10vw;
	animation-name: clip-text;
	color: #FFF;
	white-space: nowrap;
	cursor: default;
	
	&::after {
		content: "";
		position: absolute;
		z-index: 999;
		top: 7%;
		left: 0;
		right: 0;
		bottom: 0;
    background: linear-gradient(90deg, rgba(250,250,250,0.7) 0%, rgba(252,252,252,1) 100%);
		transform: scaleX(0);
		transform-origin: 0 50%;
		pointer-events: none;
		animation-name: text-revealer;
	}
	
}


@keyframes clip-text {
	from {
		clip-path: inset(0 100% 0 0);
	}
	to {
		clip-path: inset(0 0 0 0);
	}
}


@keyframes text-revealer {
	
	0%, 50% {
		transform-origin: 0 50%;
	}
	
	60%, 100% {
		transform-origin: 100% 50%;		
	}

	
	60% {
		transform: scaleX(1);
	}
	
	100% {
		transform: scaleX(0);
	}
}
/*
.presentation, 
.garantie,
.caracteristique{
    border: 1px solid red}
*/

.presentation{
    position: relative;
    z-index: 1000;
    margin-top: -40px;
}


.btn-preco{
    position: absolute;
    left: 150px;
    box-shadow: 0 0 20px rgba(0,0,0,0.3);
    a{padding: 20px 40px}
}


.garantie{
    width: 49%;
    text-align: right;
    padding-right: 80px;
    &-block{margin: 15px 0;color:$primary}
    .gt p{text-transform: uppercase;font-size: 20px;margin-bottom: 0}
    .gt strong{text-transform: uppercase;font-size: 30px;font-weight: 700}
    .gi{
        width: 80px;
        height:80px;
        margin-left: 10px;
        img{width: 100%;}
    }
}

.caracteristique{
    width:49%;
    max-width: 700px;
    position: relative;
    z-index: 1000;
    background: #fff;
    padding: 20px 35px;
    box-shadow: 0 0 80px rgba(0,0,0,0.1);
    ul{padding-left: 15px;
        li{padding: 5px 0}
    }  
}

.photos-page{ margin: 50px auto}

.photos-page p{margin: 0}

.fintions-page{text-align: center;}

.finition-block{
    margin: 30px auto;
    &-img{
        position: relative;
        padding-top: 30px;
        z-index: 100;
        .belstaf{
            position: absolute;
            width: 70px;
            top: 10px;
            left: 10px;
            z-index: 10}
    }
    &-txt{
        text-align: left
    }
}


.button_reveal{
    padding: 0.5em 0;
    color:$secondary;
    font-family: 'oswald';
    font-size: 18px;
    &:hover{color:$primary;cursor: pointer }
    span{
        font-weight: 900;
        font-size: 15px;
        margin-bottom: 10px;
        transform: rotate(90deg);
        display: inline-block;}
}


.car1, 
.car2, 
.car3 {
    clear:both;
    padding:20px;
    display:none;
    font-size: 15px;
    width: 100%;
    border-bottom: 1px solid #f7f7f7;
    border-right: 1px solid #f7f7f7;
    border-left: 1px solid #f7f7f7;
    box-shadow: 0 40px 40px rgba(0,0,0, 0.05);
    
    &.active{
    display:block;
    }
    h3{color: $secondary;font-size: 20px}
  
}

.moteur-page{margin:140px auto 80px;}

.moteur{margin:50px auto;text-align: center;}

.block-moteur h3{font-size: 20px}

.block-moteur.bml,
.block-moteur.bmr{
    width: 25%;
    min-width:390px;
    padding:0 40px 30px;
    background: #fff;
    position: relative;
    text-align: left;
    z-index: 100;
    color:$primary;
    box-shadow: 0 0 20px rgba(0,0,0, 0.10);
    strong{color:#474747}
    p{margin: 0}
}

.block-moteur.bml{margin-right:-7%}
.block-moteur.bmr{margin-left: -7%}

.block-moteur.bmc{
    width: 40%;
    position: relative;
    z-index: 10;
}


.defi-page{
    margin: 80px auto 0;
    &-img{
        width:52%;
        position:relative;
        z-index:10;
    }
    &-text{
        width:60%;
        position:relative;
        z-index:100;
        margin-left: -13%;
        background: #fff;
        padding:30px 50px;
    }
}



#form{padding-top:10px;}

#precommande { 
    margin-top: 70px;
    position: relative;
    z-index: 100;
}

#bg-preco{
    position: absolute;
    z-index: 10;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    height: 400px;
    background: #eeeeee;
}

    

#precommande .form{ 
    padding: 30px 50px;
    position: relative;
    z-index: 100;
    background: #fff;
    box-shadow: 0 0 100px rgba(0,0,0,0.1);
}
    


#precommande .champs{ 
    margin: 20px 0.5%;
    width:48%;}

#precommande .champs.preco-message{
    width:100%;
    textarea{ height: 150px; width:98%;}
}

#precommande .champs-control input {
    border: none;
    border-bottom: 1px solid #4d504e;
    width: 100%;
    background: 0 0;
    color: #474747!important;
}

#precommande .alert-info {
    background-color: transparent;
    border: none;
    width: 70%;
    display: inline-block;
    vertical-align: top;
}

.obligatoire{
    width: 29%;
    padding: 0.75rem 1.25rem;
    display: inline-block;
    vertical-align: top;
    text-align: right;
    font-size: 14px;
}

.entretien h2{color: $secondary;font-size: 1.5rem; text-align: center}

.entretien h2:after {
    display: block;
    width: 70px;
    background: $primary;
    border-bottom: 1px #fff;
    height: 5px;
    margin: 15px auto 0;
    content: '';
}

.block-entretien.w50{
    width: 47%;
    margin: 1%;
}
.block-entretien.w100{
    width: 96%;
    margin: 1%;
}

@media(max-width:991px){
    .block-entretien.w50, .block-entretien.w100{width: 100%;min-height: inherit !important;box-shadow: none !important;text-align: left !important;}
}

.block-entretien.w50,
.block-entretien.w100{
    padding: 20px 40px;
    text-align: center;
    min-height: 380px;
    box-shadow: 0 0 50px rgba(0,0,0,0.1);
}

.block-entretien.bl{
    padding-left: 50px;
    border-left: 5px solid $primary;
}


.option-page{margin: 80px auto 0}

.option-page .swiper-wrapper.widgetAccessoires-wrapper{    justify-content: center;}

.widgetAccessoires-name{
    display: block;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 0 20px;
    color: $primary;
    margin: 20px auto;
}

.widgetAccessoires-text {
    display: block;
    font-size: 16px;
    padding: 0 20px;
    color:#474747;
    margin: 20px auto;

}



@media(max-width:1080px){
    .top-page-marque strong{font-size: 150px;}
    .top-page-img, .top-page-intro{width: 100%; margin: 0;   padding:0 7%;}
    .presentation{margin: 0;    padding: 50px 0;}
    .btn-preco {   position: relative;   text-align: center;   margin-bottom: 50px;   left: 0;    box-shadow: none;}
    .block-moteur.bmc {width:100%;margin: 10px auto}
    .block-moteur.bml, .block-moteur.bmr{width:280px;margin: 10px auto}
    .defi-page-img {  width: 100%;  margin: auto;  text-align: center;}
    .defi-page-text {  width: 100%;margin: 0 auto;}
}



@media(max-width:991px){
    .top-page-img img {  margin-top: 50px;  margin-bottom: -40px;}
    .entretien h2{text-align: left}
    .entretien h2:after{margin: 15px 0}
}

@media(max-width:767px){
  .top-page-marque strong { font-size: 100px;}
  .garantie, .caracteristique{width: 90%;margin: 0 5%; max-width: inherit}
    
}

@media(max-width:630px){
    .top-page-marque{top:0}
    .top-page-marque strong{font-size: 85px;}
    .top-page-intro h1 {  font-size: 40px;}
    .garantie{padding: 0;}
}

@media(max-width:500px){
    .top-page-marque strong{font-size: 70px;}
    h2.big.middle{font-size: 40px;}
    #precommande .champs{width: 98%;}
}


@media(max-width:380px){
    .top-page-marque strong{font-size: 50px;}
}
































